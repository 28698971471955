import React from "react";
import theme from "theme";
import { Theme, Image, Box, Text, Link, Section, Strong } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				StarGaze Events
			</title>
			<meta name={"description"} content={"Creare ricordi indimenticabili"} />
			<meta property={"og:title"} content={"StarGaze Events"} />
			<meta property={"og:description"} content={"Creare ricordi indimenticabili"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-2.jpg?v=2024-06-10T08:22:40.317Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-2.jpg?v=2024-06-10T08:22:40.317Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-2.jpg?v=2024-06-10T08:22:40.317Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-2.jpg?v=2024-06-10T08:22:40.317Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-2.jpg?v=2024-06-10T08:22:40.317Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-2.jpg?v=2024-06-10T08:22:40.317Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-2.jpg?v=2024-06-10T08:22:40.317Z"} />
		</Helmet>
		<Components.Header2>
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="text">
				StarGaze{" "}
				<br />
				Events
			</Override>
		</Components.Header2>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				justify-content="flex-start"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 32px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 32px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://uploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-3.jpg?v=2024-06-10T08:22:40.334Z"
					object-fit="contain"
					width="100%"
					height="100%"
					border-radius="24px"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					min-height="100%"
					max-width="100%"
					sm-min-height="100vw"
					srcSet="https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-3.jpg?v=2024-06-10T08%3A22%3A40.334Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-3.jpg?v=2024-06-10T08%3A22%3A40.334Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-3.jpg?v=2024-06-10T08%3A22%3A40.334Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-3.jpg?v=2024-06-10T08%3A22%3A40.334Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-3.jpg?v=2024-06-10T08%3A22%3A40.334Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-3.jpg?v=2024-06-10T08%3A22%3A40.334Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-3.jpg?v=2024-06-10T08%3A22%3A40.334Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 0px 16px 16px"
				justify-content="center"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline2"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Benvenuti agli eventi StarGaze
				</Text>
				<Text margin="0px 0px 40px 0px" color="--greyD3" font="--lead" lg-text-align="center">
					Benvenuto a StarGaze Events, dove trasformiamo i tuoi sogni in realtà. Siamo specializzati nella creazione di esperienze indimenticabili, sia che tu stia pianificando un matrimonio, un evento aziendale, una festa di compleanno o qualsiasi altra occasione speciale. Il nostro team di organizzatori e coordinatori di eventi professionisti si impegna a rendere il vostro evento davvero unico e memorabile.
				</Text>
				<Link
					href="/services"
					padding="12px 24px 12px 24px"
					color="--light"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 16px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					sm-margin="0px 0px 16px 0px"
					sm-text-align="center"
					background="--color-primary"
					hover-transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-primary"
				>
					I nostri servizi
				</Link>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0" background="--color-dark">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
				padding="0px 80px 0px 0px"
				lg-padding="0px 30px 0px 0px"
				md-padding="0px 0px 0px 0px"
			>
				<Text margin="0px 0px 0px 0px" color="--light" font="--headline2" md-margin="0px 0px 30px 0px">
					Chi siamo
				</Text>
			</Box>
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
			>
				<Text margin="0px 0px 40px 0px" color="--light" font="--base">
					Noi di StarGaze Events crediamo che ogni evento sia un'opportunità per creare ricordi duraturi. Con anni di esperienza nel settore della pianificazione di eventi, abbiamo le competenze per gestire tutti gli aspetti del tuo evento, dall'ideazione all'esecuzione. La nostra attenzione ai dettagli, la creatività e l'impegno per l'eccellenza ci distinguono dalla concorrenza. Lavoriamo a stretto contatto con te per comprendere la tua visione e darle vita, assicurandoci che ogni dettaglio sia perfetto.
				</Text>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-11">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					sm-font="--headline3"
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					md-text-align="left"
				>
					Perché scegliere noi?
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					Comprendiamo che pianificare un evento può essere stressante, ecco perché offriamo una gamma completa di servizi per rendere il processo il più agevole possibile. Il nostro team è esperto nella gestione di tutti i tipi di eventi, grandi o piccoli, e siamo orgogliosi della nostra capacità di fornire ogni volta risultati eccezionali.
					<br />
					<br />
					<Strong>
						{" "}Ecco alcuni motivi per scegliere gli eventi StarGaze:{" "}
						<br />
					</Strong>
					Servizio personalizzato: Ci prendiamo il tempo per comprendere le tue esigenze e preferenze uniche, creando un piano personalizzato che riflette la tua visione.
					<br />
					Coordinamento di esperti:{" "}
					<br />
					I nostri coordinatori esperti gestiscono tutta la logistica, assicurando che il tuo evento si svolga senza intoppi dall'inizio alla fine.
					<br />
					Soluzioni creative: Portiamo sul tavolo nuove idee e soluzioni innovative, facendo risaltare il tuo evento.
					<br />
					Attenzione ai dettagli: Nessun dettaglio è troppo piccolo per noi. Pianifichiamo meticolosamente ogni aspetto del vostro evento per garantirne la perfezione.
					<br />
					Pianificazione senza stress: Eliminiamo lo stress dalla pianificazione degli eventi, permettendoti di rilassarti e goderti il ​​tuo giorno speciale.
				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				align-self="center"
				height="100%"
				align-items="center"
				justify-content="center"
			>
				<Box
					width="100%"
					height="auto"
					padding="0px 0px 70% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
				>
					<Image
						position="absolute"
						width="100%"
						top="auto"
						left={0}
						src="https://uploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-2.jpg?v=2024-06-10T08:22:40.317Z"
						object-fit="cover"
						display="block"
						right={0}
						bottom="0px"
						min-height="100%"
						height="100%"
						srcSet="https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-2.jpg?v=2024-06-10T08%3A22%3A40.317Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-2.jpg?v=2024-06-10T08%3A22%3A40.317Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-2.jpg?v=2024-06-10T08%3A22%3A40.317Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-2.jpg?v=2024-06-10T08%3A22%3A40.317Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-2.jpg?v=2024-06-10T08%3A22%3A40.317Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-2.jpg?v=2024-06-10T08%3A22%3A40.317Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6666b79c6bbd2000236b649f/images/2-2.jpg?v=2024-06-10T08%3A22%3A40.317Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
		</Section>
		<Components.Cta>
			<Override slot="text" font="--lead">
				<Strong>
					Servizi aggiuntivi
				</Strong>
				<br />
				Oltre ai nostri servizi standard di pianificazione degli eventi, offriamo una gamma di servizi aggiuntivi per migliorare il vostro evento. Dalle decorazioni personalizzate alle composizioni floreali, dall'intrattenimento al catering, abbiamo tutto ciò di cui hai bisogno per rendere il tuo evento davvero speciale. Il nostro team lavora con una rete di fornitori fidati per fornirti servizi e prodotti della massima qualità.
			</Override>
		</Components.Cta>
		<Components.Footer>
			<Override slot="link" />
			<Override slot="text6" />
			<Override slot="text" />
			<Override slot="text2" />
			<Override slot="text3" />
			<Override slot="text1" />
			<Override slot="link1" />
			<Override slot="text4" />
			<Override slot="text5" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65bb720efdcae00021dd9fe2"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});